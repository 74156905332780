import React, { useEffect, useState } from 'react';
import ItineraryInDetailStyle, { ErrorStyle } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import IntineraryStandardComponent from './ItineraryInDetailStandard';
import ItineraryInDetailEventComponent from './ItineraryInDeatilEvent';
import useQueryApi from 'src/hooks/useApiQuery';
import { getItinerary } from 'src/api/apiGetItinerary';
import NotFound from 'src/components/notFoundData';
import { Button, Carousel, Skeleton } from 'antd';
import { fillMissingDates, modifyURL } from 'src/const/contain';
import PricingBookingAndAdditionalAgentComment from 'src/components/pricingBookingAndAdditionalAgentComment';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import icons from 'src/assets/icon';
import { getQuoteDetailVersion } from 'src/api/apiGetQuoteDetailVersion';
import ModalChoseMainImage from './mainImageComponent/ModalChoseImage';
import ModalComponent from 'src/components/modalCustom';

interface ItineraryInDetailProps {
    customProp?: any;
    dataJsonProp?: any;
    image?: string;
    onUpdateValueInput?: (value: any) => void;
    roleBtnEditOnHeader?: (value: any) => void;
    refetchValue?: () => void;
    roleBtn?: (value: any) => void;
    refetchDataCheck?: () => void;
}
const ItineraryInDetailComponent: React.FC<ItineraryInDetailProps> = (
    props,
) => {
    const {
        customProp,
        dataJsonProp,
        // image,
        // onUpdateValueInput
        roleBtnEditOnHeader,
        refetchValue,
        roleBtn,
        refetchDataCheck,
    } = props;
    const queryParams = new URLSearchParams(window.location.search);
    const isRole = useSelector(roleSelector);
    const [showEditAgentComment, setShowEditAgentComment] =
        useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [listImage, setListImage] = useState<string[]>([]);

    const type = queryParams.get('type');
    const idItinerary = queryParams.get('id') || undefined;
    const tourName = dataJsonProp?.tourName || '';
    const quoteTitle = dataJsonProp?.title || '';
    const avatarAgent = dataJsonProp?.resConsultant?.image || null;
    const externalContent = dataJsonProp?.externalContent || [];

    const words = tourName?.split(' ');
    const firstTwoWords =
        words[0] === 'Masters' ? 'Masters' : `${words[0]} ${words[1]}`;
    const quoteWhyBookWithYgtTheOpen =
        customProp?.quoteWhyBookWithYgtTheOpen?.content[0]?.content || [];
    const quoteWhyBookWithYgtTheRyderCup =
        customProp?.quoteWhyBookWithYgtTheRyderCup?.content[0]?.content || [];
    const quoteWhyBookWithYgtTheMasters =
        customProp?.quoteWhyBookWithYgtTheMasters?.content[0]?.content || [];
    const quoteWhyBookWithYgtSolheimCup =
        customProp?.quoteWhyBookWithYgtSolheimCup?.content[0]?.content || [];

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const {
        data: dataItinerary,
        isLoading,
        isError,
        refetch,
    } = useQueryApi(
        ['idItinerary', idItinerary],
        () => {
            if (!type) {
                return getQuoteDetailVersion(idItinerary);
            } else {
                return getItinerary(idItinerary);
            }
        },
        {
            enabled: !!idItinerary,
        },
    );

    useEffect(() => {
        let images =
            (dataItinerary as { images: any })?.images ||
            (dataItinerary as { data: any })?.data?.quoteItinerary?.images;
        setListImage(images || []);
        
    }, [dataItinerary]);

    const handleLoadImage = async(imgs: string[]) => {
        setListImage(imgs || []);
        await refetch();
    }

    const checkEvent = (dataItinerary as { data: any })?.data?.introduction
        ?.isEvent;
    const dataIntineraryVersion = (dataItinerary as { data: any })?.data
        ?.quoteItinerary?.itinerary;

    const data = (dataItinerary as { itinerary: any })?.itinerary;
    const componentComment =
        (dataItinerary as { componentComments: any })?.componentComments || [];
    const additionalInfo =
        (dataItinerary as { additionalInfo: any })?.additionalInfo || '';

    const dataEvent = fillMissingDates(data || dataIntineraryVersion);

    const getMappedArrayByCondition = (condition: any) => {
        let selectedArray = [];

        switch (condition) {
            case 'The Open':
                selectedArray = quoteWhyBookWithYgtTheOpen;
                break;
            case 'Ryder Cup':
                selectedArray = quoteWhyBookWithYgtTheRyderCup;
                break;
            case 'Masters':
                selectedArray = quoteWhyBookWithYgtTheMasters;
                break;
            case 'Solheim Cup':
                selectedArray = quoteWhyBookWithYgtSolheimCup;
                break;
            default:
                return [];
        }
        const mappedArray = selectedArray.map((item: any, index: any) => {
            return {
                id: index + 1,
                content: item.content[0]?.content[0]?.value,
            };
        });

        return mappedArray;
    };
    const resultArray = getMappedArrayByCondition(firstTwoWords);
    const mapAcom =
        (Array.isArray(data) &&
            data?.map((item: any) => item?.accommodations)) ||
        (Array.isArray(dataIntineraryVersion) &&
            dataIntineraryVersion?.map((item: any) => item?.accommodations));
    const uniqueAccommodations = mapAcom
        ? Array.from(
              new Map(
                  mapAcom
                      .flat()
                      .map((accommodation: any) => [
                          accommodation.name,
                          accommodation,
                      ]),
              ).values(),
          )
        : [];
    const allComments: any =
        Array.isArray(uniqueAccommodations) &&
        uniqueAccommodations?.map((subArray: any) => subArray.comments || []);

    const mapGolf =
        (Array.isArray(data) && data?.map((item: any) => item?.golfs)) ||
        (Array.isArray(dataIntineraryVersion) &&
            dataIntineraryVersion?.map((item: any) => item?.golfs));

    const uniquegolf = mapGolf
        ? Array.from(
              new Map(
                  mapGolf
                      .flat()
                      .map((golf: any) => [golf.serviceDescription, golf]),
              ).values(),
          )
        : [];

    const allCommentsGolf =
        Array.isArray(uniquegolf) &&
        uniquegolf.flatMap((item: any) => item.comments || []);

    const renderError = () => (
        <div className="error">
            <NotFound content={<div>No data to display</div>} />
        </div>
    );

    const showComponentEditAgentComment = (valueChild: boolean = false) => {
        setShowEditAgentComment(valueChild);
    };

    const allCommentsCheck = [
        allComments[0],
        allCommentsGolf,
        componentComment,
    ];

    const shouldShowCommentComponent = allCommentsCheck?.some(
        (comments) => comments?.length > 0,
    );
    const renderLoading = () => (
        <ErrorStyle>
            <Skeleton
                className="imgGoflTravel"
                active={true}
                style={{ width: '100%' }}
            />
        </ErrorStyle>
    );

    const renderContentStandard = (data: any) =>
        data?.length === 0 ? (
            renderError()
        ) : (
            <div>
                {data?.map((item: any, index: any) => {
                    const order = index + 1;
                    const {
                        accommodations,
                        carHire,
                        flights,
                        transfers,
                        golfs,
                    } = item;
                    const isLastItem = index === data.length - 1;
                    return (
                        <div key={index}>
                            {!(
                                accommodations ||
                                carHire ||
                                flights ||
                                transfers ||
                                golfs
                            ) ? null : (
                                <IntineraryStandardComponent
                                    dataItinerary={item}
                                    dateNumber={order}
                                    lastItem={isLastItem}
                                    AvatarAgent={avatarAgent}
                                    totalItem={data?.length}
                                    refetchApiItinarery={refetch}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isloading={isLoading}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );

    const renderContentEvent = (
        dataEvent: any,
        customProp: any,
        tourName: any,
    ) =>
        dataEvent?.length === 0 ? (
            renderError()
        ) : (
            <div>
                {dataEvent?.map((item: any, index: any) => {
                    const order = index + 1;
                    const isLastItem = index === dataEvent.length - 1;
                    return (
                        <div key={index}>
                            <ItineraryInDetailEventComponent
                                dataItinerary={item}
                                dateNumber={order}
                                customProp={customProp}
                                tourName={tourName}
                                lastItem={isLastItem}
                                AvatarAgent={avatarAgent}
                                totalItem={dataEvent?.length}
                                refetchApiItinarery={refetch}
                                roleBtnEditOnHeader={roleBtnEditOnHeader}
                                quoteTitle={quoteTitle}
                                isloading={isLoading}
                            />
                        </div>
                    );
                })}
                <div className="viewAddtionEdit">
                    <div className="addtionEdit">
                        <div
                            className="iconAddCommentAddconditional"
                            onClick={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                        >
                            <img
                                alt=""
                                className="iconAdd"
                                src={icons.addComment}
                            />
                        </div>
                        {!additionalInfo && isRole === 'Edit mode' && (
                            <PricingBookingAndAdditionalAgentComment
                                additional={'Additional Information'}
                                quoteTuorName={quoteTitle}
                                refetch={refetch}
                                dataAgentComment={null}
                                showEdit={showEditAgentComment}
                                changeShowEdit={() =>
                                    showComponentEditAgentComment(
                                        !showEditAgentComment,
                                    )
                                }
                            />
                        )}

                        {additionalInfo && (
                            <PricingBookingAndAdditionalAgentComment
                                additional={'Additional Information'}
                                showEdit={showEditAgentComment}
                                dataAgentComment={additionalInfo}
                                quoteTuorName={quoteTitle}
                                refetch={refetch}
                                changeShowEdit={() =>
                                    showComponentEditAgentComment(
                                        !showEditAgentComment,
                                    )
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
        );

    return (
        <div>
            {isRole === 'Edit mode' && (
                <ItineraryInDetailStyle>
                    <Button
                        type="primary"
                        ghost
                        className="btnChoseImage"
                        onClick={showModal}
                    >
                        Choose image
                    </Button>
                </ItineraryInDetailStyle>
            )}
            {listImage?.length > 0 && (
                <ItineraryInDetailStyle>
                    <Carousel autoplay>
                        {listImage?.map((item: any, index: any) => {
                            return (
                                <div className="viewMainImageCarosel">
                                    <img
                                        alt=""
                                        className="imgGoflTravelcarosel"
                                        src={modifyURL(item, 1000)}
                                    />
                                </div>
                            );
                        })}
                    </Carousel>
                </ItineraryInDetailStyle>
            )}
            {isError ? (
                renderError()
            ) : isLoading ? (
                renderLoading()
            ) : (
                <ItineraryInDetailStyle
                    mode={isRole}
                    showEdit={showEditAgentComment}
                    className="container"
                >
                    {(checkEvent === false || type === 'Standard') &&
                        renderContentStandard(data || dataIntineraryVersion)}
                    {(checkEvent === true || type === 'Event') && (
                        <>
                            {renderContentEvent(
                                dataEvent,
                                customProp,
                                tourName,
                            )}
                            {resultArray.length > 0 && (
                                <div className="whyBookWithYGTComponent">
                                    <div className="pleaseRead">
                                        Why Book With YGT
                                    </div>
                                    {resultArray.map(
                                        (item: any, index: any) => (
                                            <div
                                                className="discriptionWBW"
                                                key={index}
                                            >
                                                <FontAwesomeIcon
                                                    className="iconCheck"
                                                    icon={faCircleCheck}
                                                />
                                                <div className="contentWBWYGT">
                                                    {item?.content}
                                                </div>
                                            </div>
                                        ),
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    {shouldShowCommentComponent && (
                        <div className="viewPleaseRead">
                            <div className="pleaseRead">Please Read</div>
                            <div className="contentPleaseRead">
                                Important Information About Your Proposed Trip
                            </div>
                            <div className="viewDiscriptionPleaseRead">
                                {uniqueAccommodations?.length > 0 && (
                                    <div>
                                        {uniqueAccommodations.map(
                                            (item, index) => {
                                                return (
                                                    <div key={index}>
                                                        {item.comments?.length >
                                                            0 && (
                                                            <>
                                                                <div className="titlePleaseRead">
                                                                    {item.name}
                                                                </div>
                                                                <ul>
                                                                    {item.comments.map(
                                                                        (
                                                                            comment: any,
                                                                            idx: any,
                                                                        ) => (
                                                                            <li
                                                                                className="discriptionPleaseRead"
                                                                                key={
                                                                                    idx
                                                                                }
                                                                            >
                                                                                {
                                                                                    comment.text
                                                                                }
                                                                            </li>
                                                                        ),
                                                                    )}
                                                                </ul>
                                                            </>
                                                        )}
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                )}
                                {uniquegolf?.length > 0 && (
                                    <div>
                                        {uniquegolf.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    {item.comments?.length >
                                                        0 && (
                                                        <>
                                                            <div className="titlePleaseRead">
                                                                {
                                                                    item.serviceDescription
                                                                }
                                                            </div>
                                                            <ul>
                                                                {item.comments.map(
                                                                    (
                                                                        comment: any,
                                                                        idx: any,
                                                                    ) => (
                                                                        <li
                                                                            className="discriptionPleaseRead"
                                                                            key={
                                                                                idx
                                                                            }
                                                                        >
                                                                            {
                                                                                comment.text
                                                                            }
                                                                        </li>
                                                                    ),
                                                                )}
                                                            </ul>
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                {componentComment?.length > 0 && (
                                    <div className="titlePleaseRead">
                                        Additional Comments
                                    </div>
                                )}
                                {componentComment?.length > 0 &&
                                    componentComment?.map(
                                        (item: any, index: any) => (
                                            <div key={index}>
                                                <ul>
                                                    <li className="discriptionPleaseRead">
                                                        {item.text}
                                                    </li>
                                                </ul>
                                            </div>
                                        ),
                                    )}
                            </div>
                        </div>
                    )}
                </ItineraryInDetailStyle>
            )}

            <ModalComponent
                width={1000}
                visible={isModalVisible}
                onClose={handleClose}
                title="Choose main image"
                content={
                    <ModalChoseMainImage
                        externalContent={externalContent}
                        close={() => handleClose()}
                        refetch={refetchValue}
                        roleBtn={roleBtn}
                        selectedListImage={listImage}
                        refetchDataCheck={refetchDataCheck}
                        onSendImages={handleLoadImage}
                    />
                }
            />
        </div>
    );
};

export default React.memo(ItineraryInDetailComponent);
