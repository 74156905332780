import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch,
    useLocation,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faCoins } from '@fortawesome/free-solid-svg-icons';
import IntroductionGoflTravel from './IntroductionGolfTravel';
import QouteNavigateCompoment from './QuoteNavigate';
import QuoteDetailPageStyle from './style';
import images from 'src/assets/image';
import { nameView } from 'src/const/enum';
import ItineraryInDetailComponent from './ItineraryInDetail';
import PricingAndBookingComponent from './PricingAndBooking';
import ImportantInformationComponent from './ImportantInformation';
import TermsAndCoditionsComponent from './TermsAndCoditions';
import { Button, Spin } from 'antd';
import { updateCheckRoleSuccess } from 'src/redux/actions/roleCheckAction';
import QuoteTitleAndInputUpdateComponent from 'src/components/viewQuoteTitle';
import ModalComponent from 'src/components/modalCustom';
import ModalConfirmComponent from './modalConfirm';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import useQueryApi from 'src/hooks/useApiQuery';
import { checkChangeApi } from 'src/api/apiCheckChange';
import { ModalCopyLink } from './QuoteNavigate/style';
import Search from 'antd/es/input/Search';
import { toast } from 'react-toastify';
import { AnyARecord } from 'dns';
import { versionIdSelector } from 'src/redux/selectors/versionIdSelecter';

interface destopProps {
    Imange?: any;
    dataContenFull?: any;
    dataJsonChild?: any;
    dataVerSions?: any;
    onUpdate?: () => void;
    intinerary?: any;
}

const DeskTopQuoteDetailPage: React.FC<destopProps> = ({
    Imange,
    dataContenFull,
    dataJsonChild,
    dataVerSions,
    onUpdate,
    intinerary,
}) => {
    const [roleBtn, setRoleBtn] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [mainImage, setMainImage] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingGenerate, setLoadingGenerate] = useState<boolean>(false);
    const [generateLinkState, steGenerateLinkState] = useState<boolean>(false);
    const [childData, setChildData] = useState<any>({
        id: nameView.GOFL_TRAVEL_EXPERT,
        content: 'Introduction from your Golf Travel Expert',
    });
    const [dataJson, setDataJson] = useState<any>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModaCopylVisible, setIsModaCopylVisible] = useState(false);

    const [modeModal, setModeModal] = useState<string>('');
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');
    const role: string = location?.state || '';
    const isRole = useSelector(roleSelector);
    const imageJsonData = dataJsonChild?.mainCourse?.image || null;
    const idQuote = queryParams.get('id');
    const type = queryParams.get('type');
    const idIntro = dataJsonChild?.id || '';
    localStorage.setItem('idIntro', idIntro);
    const unallocatedAccommodation =
        dataJsonChild?.unallocatedAccommodation || false;
    localStorage.setItem('unallocatedAccommodation', unallocatedAccommodation);
    const promotionalBanner = dataContenFull?.promotionalBanner || '';
    const promotionBannerEvent =
        dataContenFull?.promotionalBannerTournament || '';
    const isEvent = dataJsonChild?.isEvent;
    const versionId = useSelector(versionIdSelector);

    const {
        data: dataChange,
        refetch,
        isSuccess,
    } = useQueryApi(['idCheck'], () => checkChangeApi(idQuote), {
        enabled: false,
        onSuccess: (data: any) => {
            console.log('API called successfully', data);
        },
        onError: (error: AnyARecord) => {
            console.error('API call failed', error);
        },
    });

    const statusChange = (dataChange as { changed: boolean })?.changed;

    useEffect(() => {
        dispatch(updateCheckRoleSuccess('Preview mode'));
        if (imageJsonData) {
            setMainImage(imageJsonData);
        } else {
            setMainImage(Imange);
        }
        setDataJson(dataJsonChild);
    }, [Imange, dataJsonChild, dispatch, role, dataChange, imageJsonData]);

    const handleChildDataChange = (data: string) => {
        setChildData(data);
    };

    const changeRoleEdit = () => {
        setRoleBtn(!roleBtn);
        dispatch(updateCheckRoleSuccess('Edit mode'));
    };

    const ModalFinish = (valueChild: boolean) => {
        setRoleBtn(valueChild);
        dispatch(updateCheckRoleSuccess('Preview mode'));
    };

    const showModal = (value?: string) => {
        setIsModalVisible(true);
        if (value) {
            setModeModal(value);
        }
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const handleCloseModalCopy = () => {
        setIsModaCopylVisible(false);
    };

    const onCopy = () => {
        navigator.clipboard
            .writeText(
                `${process.env.REACT_APP_PUBLIC_ENVIROMENT}/quoteDetail/introduction?id=${versionId}`,
            )
            .then(() => {
                toast.success('Copied to clipboard!');
                setIsModaCopylVisible(false);
                refetch();
            })
            .catch((err) => {
                toast.error('Failed to copy to clipboard. Please try again.');
                setIsModaCopylVisible(false);
            });
    };

    const sendMail = async() => {
        steGenerateLinkState(false);
        setLoading(true);
        const result: any = await refetch();
        const { data } = result;
        if (data) {
            showModal('send');
            setLoading(false);
        }
    };

    const generateLink = async () => {
        setLoadingGenerate(true);
        const result: any = await refetch();
        const { data } = result;
        if (data) {
            const isChange = data?.changed || false;
            if (isChange) {
                steGenerateLinkState(true);
                showModal('send');
            } else {
                setIsModaCopylVisible(true);
            }
        }

        setLoadingGenerate(false);
    };

    // value data json
    const resConsultant = dataJson?.resConsultant;
    const quoteTitle = dataJson?.title;

    return (
        <Router>
            <QuoteDetailPageStyle>
                <div className="deskTopView">
                    {mode === 'PreviewMode' &&
                        dataContenFull &&
                        dataJsonChild && (
                            <div className="headerShowRole">
                                <div className="viewRole viewRoleMobile">
                                    <div>
                                        <span className="styleContentRole">
                                            You're viewing quote in{' '}
                                        </span>
                                        <span className="styleRole">
                                            {isRole}
                                        </span>
                                    </div>
                                    <div className="viewBtnRole">
                                        {roleBtn && (
                                            <Button
                                                type="primary"
                                                className="btnReset"
                                                onClick={() =>
                                                    ModalFinish(false)
                                                }
                                                ghost
                                            >
                                                {'Reset Changes'}
                                            </Button>
                                        )}
                                        {!roleBtn ? (
                                            <Button
                                                type="primary"
                                                className="btnEdit"
                                                onClick={changeRoleEdit}
                                                ghost
                                            >
                                                {'Edit'}
                                            </Button>
                                        ) : (
                                            <Button
                                                type="primary"
                                                className="btnEdit"
                                                onClick={() =>
                                                    showModal('save')
                                                }
                                                ghost
                                            >
                                                {'Save'}
                                            </Button>
                                        )}
                                        {!roleBtn && (
                                            <Spin
                                                spinning={loadingGenerate}
                                                delay={500}
                                            >
                                                <Button
                                                    type="primary"
                                                    className="btnSend"
                                                    onClick={generateLink}
                                                >
                                                    Generate link
                                                </Button>
                                            </Spin>
                                        )}
                                        {!roleBtn && (
                                            <Spin
                                                spinning={loading}
                                                delay={500}
                                            >
                                                <Button
                                                    type="primary"
                                                    className="btnSend"
                                                    onClick={sendMail}
                                                >
                                                    Send
                                                </Button>
                                            </Spin>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    <div className="headerPage">
                        <div className="logoHeader">
                            <img
                                alt=""
                                className="LogoIMG"
                                src={images.logoYGT}
                            />
                        </div>

                        {((isEvent && promotionBannerEvent?.trim()) ||
                            (type === 'Event' &&
                                promotionBannerEvent?.trim())) && (
                            <div className="viewPromotion">
                                <div className="contentBtnPromotion">
                                    <FontAwesomeIcon
                                        icon={faCoins}
                                        className="iconCoins"
                                    />
                                    <p>{promotionBannerEvent}</p>
                                </div>
                            </div>
                        )}
                        {((!isEvent && promotionalBanner?.trim()) ||
                            (type === 'Standard' &&
                                promotionalBanner?.trim())) && (
                            <div className="viewPromotion">
                                <div className="contentBtnPromotion">
                                    <FontAwesomeIcon
                                        icon={faCoins}
                                        className="iconCoins"
                                    />
                                    <p>{promotionalBanner}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="headerTitle">
                        <div className="titleHeader ">
                            <QuoteTitleAndInputUpdateComponent
                                showRole={isRole}
                                quoteTitle={quoteTitle}
                                onUpdateValueInput={showModal}
                            />
                        </div>
                        <div className="titleview">{childData.content}</div>
                    </div>
                    <div className="viewShowInfor">
                        <div className="viewInforLeft">
                            <Switch>
                                <Route exact path="/quoteDetail">
                                    <Redirect to="/quoteDetail/introduction" />
                                </Route>
                                <Route
                                    path="/quoteDetail/introduction"
                                    component={(props: any) => (
                                        <IntroductionGoflTravel
                                            {...props}
                                            customProp={dataContenFull}
                                            dataJsonProp={dataJson}
                                            image={mainImage}
                                            onUpdateValueInput={showModal}
                                            refetch={onUpdate}
                                            roleBtn={ModalFinish}
                                            refetchDataCheck={refetch}
                                        />
                                    )}
                                />
                                <Route
                                    path="/quoteDetail/itineraryInDetail"
                                    render={(props: any) => (
                                        <ItineraryInDetailComponent
                                            {...props}
                                            customProp={dataContenFull}
                                            dataJsonProp={dataJson}
                                            image={mainImage}
                                            onUpdateValueInput={showModal}
                                            roleBtnEditOnHeader={!roleBtn}
                                            refetchValue={onUpdate}
                                            roleBtn={ModalFinish}
                                            refetchDataCheck={refetch}
                                        />
                                    )}
                                />
                                <Route
                                    path="/quoteDetail/pricingAndBooking"
                                    component={(props: any) => (
                                        <PricingAndBookingComponent
                                            {...props}
                                            dataReadyToBook={dataContenFull}
                                            dataJsonProp={dataJson}
                                            customProp={dataContenFull}
                                            image={mainImage}
                                            roleBtn={ModalFinish}
                                            refetchValue={onUpdate}
                                            refetchDataCheck={refetch}
                                            dataVersions={dataVerSions}
                                        />
                                    )}
                                />
                                <Route
                                    path="/quoteDetail/importantInformation"
                                    component={(props: any) => (
                                        <ImportantInformationComponent
                                            {...props}
                                            dataImportanInfor={dataContenFull}
                                            dataJsonProp={dataJson}
                                            image={mainImage}
                                            refetchData={onUpdate}
                                            roleBtn={ModalFinish}
                                            refetchDataCheck={refetch}
                                        />
                                    )}
                                />
                                <Route
                                    path="/quoteDetail/termsAndCoditions"
                                    component={(props: any) => (
                                        <TermsAndCoditionsComponent
                                            {...props}
                                            customProp={dataContenFull}
                                            dataJsonProp={dataJson}
                                            image={mainImage}
                                            refetchData={onUpdate}
                                            roleBtn={ModalFinish}
                                            refetchDataCheck={refetch}
                                        />
                                    )}
                                />
                                <Route
                                    render={() => (
                                        <Redirect to="/quoteDetail/introduction" />
                                    )}
                                />
                            </Switch>
                        </div>
                        <div className="viewInforRight">
                            <QouteNavigateCompoment
                                onDataChange={handleChildDataChange}
                                dataJsonResConsultant={resConsultant}
                                groupData={dataJson}
                                intinerary={intinerary}
                            />
                        </div>
                    </div>
                </div>
                <ModalComponent
                    width={500}
                    visible={isModalVisible}
                    onClose={handleClose}
                    title="Confirmation"
                    content={
                        <ModalConfirmComponent
                            quoteTitle={quoteTitle}
                            close={handleClose}
                            roleBtn={ModalFinish}
                            modeView={modeModal}
                            updateData={onUpdate}
                            dataCheck={dataChange}
                            refetchDataCheck={refetch}
                            generateLinkState={generateLinkState}
                        />
                    }
                />
                <ModalComponent
                    width={500}
                    visible={isModaCopylVisible}
                    onClose={handleCloseModalCopy}
                    title="Share your quote with your fellow golfers"
                    content={
                        <ModalCopyLink>
                            <Search
                                className="inputCopy"
                                placeholder="input search text"
                                value={`${process.env.REACT_APP_PUBLIC_ENVIROMENT}/quoteDetail/introduction?id=${versionId}`}
                                readOnly
                                enterButton="Copy"
                                size="large"
                                onSearch={onCopy}
                            />
                            <div className="contenShare">
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    className="faCircleInfo"
                                />
                                Anyone with the link will be able to see this
                                quote
                            </div>
                        </ModalCopyLink>
                    }
                />
            </QuoteDetailPageStyle>
        </Router>
    );
};

export default DeskTopQuoteDetailPage;
