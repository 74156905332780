import React, { useEffect, useState } from 'react';
import PricingAndBookingStyle, {
    PricingAndBookingMainImageStyle,
} from './style';
import images from 'src/assets/image';
import { useLocation } from 'react-router-dom';
import { Skeleton, Spin } from 'antd';
import ItemPricingBooking from 'src/components/itemPricingBooking';
import ReadyToBookComponent from 'src/components/readyToBook';
import { formatCurrency } from 'src/const/contain';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import icons from 'src/assets/icon';
import PricingBookingAndAdditionalAgentComment from 'src/components/pricingBookingAndAdditionalAgentComment';
import useQueryApi from 'src/hooks/useApiQuery';
import { getImageApi } from 'src/api/apiGetImage';
import { getImageContentFromExternalIdApi } from 'src/api/apiGetImageAndContentfFromExternalId';
import { ImageExternalContent } from 'src/types';
import MainImageComponent from 'src/components/mainImageComponent';

interface PricingAndBookingProps {
    dataReadyToBook?: any;
    dataJsonProp?: any;
    dataVersions?: any;
    customProp?: any;
    image?: any;
    refetchValue?: () => void;
    roleBtn?: (value: any) => void;
    refetchDataCheck?: () => void;
}

const PricingAndBookingComponent: React.FC<PricingAndBookingProps> = ({
    dataReadyToBook,
    dataJsonProp,
    dataVersions,
    customProp,
    image,
    refetchValue,
    roleBtn,
    refetchDataCheck,
}) => {
    const location: any = useLocation();
    const [showEditAgentComment, setShowEditAgentComment] =
        useState<boolean>(false);
    const [isLoading, setIsloading] = useState<boolean>(true);
    const queryParams = new URLSearchParams(location.search);
    const idQuote = queryParams.get('id') || '';
    const type = queryParams.get('type');
    const isRole = useSelector(roleSelector);
    const externalContentId = dataJsonProp?.mainCourse?.externalContentId || '';
    const tourNameTakeImage: string = dataJsonProp?.tourName || '';
    const mainImageAfterUpdate = dataJsonProp?.mainImage || '';
    const externalContent = dataJsonProp?.externalContent || [];

    const words = tourNameTakeImage?.split(' ');
    const firstTwoWords =
        words[0] === 'Masters' ? 'Masters' : `${words[0]} ${words[1]}`;
    //id image tour event
    const defaultImage = customProp?.defaultImage?.sys?.id;
    const idTheOpenImageInContentFullApi: any =
        customProp?.theOpenImage?.sys?.id || null;
    const idRyderCupImage: any = customProp?.theRyderCupImage?.sys?.id || null;
    const idMasterImage: any = customProp?.theMastersImage?.sys?.id || null;
    const idsolheimCupImageImage: any =
        customProp?.solheimCupImage?.sys?.id || null;

    const { data: imageExternalContent } = useQueryApi(
        ['image', externalContentId],
        () => getImageContentFromExternalIdApi(externalContentId),
        {
            enabled: !!externalContentId,
        },
    );

    const exxternalIdImage = (() => {
        const content = imageExternalContent as ImageExternalContent;
        if (
            content?.fields?.cldImages &&
            Array.isArray(content.fields.cldImages) &&
            content.fields.cldImages.length > 0
        ) {
            return content.fields.cldImages[0].url;
        }
        if (
            content?.fields?.images &&
            Array.isArray(content.fields.images) &&
            content.fields.images.length > 0
        ) {
            return content.fields.images[0].url;
        }
        return '';
    })();

    const getMappedArrayByCondition = (condition: any) => {
        let theImageToure = '';
        switch (condition) {
            case 'The Open':
                theImageToure = idTheOpenImageInContentFullApi;
                break;
            case 'Ryder Cup':
                theImageToure = idRyderCupImage;
                break;
            case 'Masters':
                theImageToure = idMasterImage;
                break;
            case 'Solheim Cup':
                theImageToure = idsolheimCupImageImage;
                break;
            default:
                return (theImageToure = defaultImage);
        }
        return theImageToure;
    };
    const idImageTour = getMappedArrayByCondition(firstTwoWords);

    const { data: imageTourTheOpenData } = useQueryApi(
        ['image', idImageTour],
        () => getImageApi(idImageTour),
        {
            enabled: !!idImageTour,
        },
    );

    const imageUrlTourTheOpen = (imageTourTheOpenData as any)?.fields?.file?.url
        ? `http:${(imageTourTheOpenData as any)?.fields?.file?.url}`
        : '';

    useEffect(() => {
        
        if(dataJsonProp){
            setIsloading(false)
            
        }
        if(dataVersions){
            setIsloading(false)
            
        }
    }, [dataJsonProp, dataVersions]);

    const checkEvent = dataVersions?.data?.introduction?.isEvent;
    const dataVersion = dataVersions?.data || null;
    const dataVersionIntroduction = dataVersion?.introduction || null;
    const dataVersionContenfull = dataVersion?.contentful;
    const idQuoteVersion = dataVersion?.introduction?.id;

    const avatarPricing =
        dataJsonProp?.resConsultant?.image ||
        dataVersionIntroduction?.resConsultant?.image;

    const groups = dataJsonProp?.groups || dataVersionIntroduction?.groups;

    const tourNameData =
        dataJsonProp?.tourName || dataVersionIntroduction?.tourName;

    const resultPriceGroup =
        groups && typeof groups === 'object'
            ? Object?.entries(groups)
                  ?.map(([group, items]) => ({
                      group,
                      items,
                  }))
                  .sort((a, b) => a.group.localeCompare(b.group))
            : [];

    const priceDepositAndDiscount =
        dataJsonProp?.price ||
        (dataVersionIntroduction as { price: any })?.price;

    const roundedTotalPrice = Number(priceDepositAndDiscount?.totalPrice || 0);

    const roundedTotalDeposit = Number(
        priceDepositAndDiscount?.totalDeposit || 0,
    );

    const totalDueday = priceDepositAndDiscount?.dueDate;

    const deposit2 = priceDepositAndDiscount?.deposit2;

    const deposit3 = priceDepositAndDiscount?.deposit3;

    const priceAgentComment =
        dataJsonProp?.priceAgentComment ||
        (dataVersionIntroduction as { priceAgentComment: any })
            ?.priceAgentComment;
    const quoteTitle =
        dataJsonProp?.title ||
        (dataVersionIntroduction as { title: any })?.title;

    const showComponentEditAgentComment = (valueChild: boolean = false) => {
        setShowEditAgentComment(valueChild);
    };
    const departureDate =
        dataJsonProp?.departureDate ||
        (dataVersionIntroduction as { departureDate: any })?.departureDate;

    return (
        <>
            <PricingAndBookingMainImageStyle>
                <MainImageComponent
                    checkEvent={checkEvent}
                    type={type}
                    mainImage={
                        mainImageAfterUpdate ? mainImageAfterUpdate : image
                    }
                    exxternalIdImage={
                        mainImageAfterUpdate
                            ? mainImageAfterUpdate
                            : exxternalIdImage
                    }
                    imageUrlTourTheOpen={
                        mainImageAfterUpdate
                            ? mainImageAfterUpdate
                            : imageUrlTourTheOpen
                    }
                    externalContent={externalContent}
                    refetch={refetchValue}
                    roleBtn={roleBtn}
                    selectedImageOld={mainImageAfterUpdate}
                    refetchDataCheck={refetchDataCheck}
                />
            </PricingAndBookingMainImageStyle>

            <PricingAndBookingStyle mode={isRole}>
                <Spin spinning={isLoading} delay={500}>
                    <div className="viewEditpricing">
                        <div
                            className="iconAddComment"
                            onClick={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                        >
                            <img
                                alt=""
                                className="iconAdd"
                                src={icons.addComment}
                            />
                        </div>
                        <div className="viewEditMode">
                            <div className="areaTitleQuote">
                                <span className="titleQuote">
                                    Your quote is valid for 48 hours.
                                </span>
                            </div>
                            <div className="areaTitleQuote">
                                <span className="quoteReferent">
                                    Quote Reference:{' '}
                                    {type ? idQuote : idQuoteVersion}
                                </span>
                            </div>
                            {resultPriceGroup.length > 0 &&
                                resultPriceGroup?.map((item, index) => {
                                    return (
                                        <div
                                            className="viewItemPricing"
                                            key={index}
                                        >
                                            <ItemPricingBooking
                                                itemData={item}
                                                numberItem={
                                                    resultPriceGroup.length
                                                }
                                                tourName={tourNameData}
                                                departureDate={departureDate}
                                            />
                                        </div>
                                    );
                                })}
                            <div className="areaEnd">
                                <div className="viewTotalPrice">
                                    <div className="contentTotalPrice">
                                        Total price
                                    </div>
                                    <div className="priceTotal">
                                        £{formatCurrency(roundedTotalPrice)}
                                    </div>
                                </div>
                                {(checkEvent === false ||
                                    type === 'Standard') && (
                                    <div>
                                        {roundedTotalDeposit ===
                                            roundedTotalPrice && (
                                            <div className="viewTotalPrice">
                                                <div className="contentTotalDeposit">
                                                    Deposit due now
                                                </div>
                                                <div className="priceDeposit">
                                                    £
                                                    {formatCurrency(
                                                        Number(
                                                            roundedTotalDeposit,
                                                        ),
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {roundedTotalDeposit !==
                                            roundedTotalPrice &&
                                            Number(deposit2?.amount) !== 0 && (
                                                <>
                                                    <div className="viewTotalPrice">
                                                        <div className="contentTotalDeposit">
                                                            Deposit due{' '}
                                                            {moment(
                                                                deposit2?.dueDate,
                                                                'DD/MM/YYYY',
                                                            )
                                                                .utc()
                                                                .format(
                                                                    'DD/MM/YYYY',
                                                                )}
                                                        </div>
                                                        <div className="priceDeposit">
                                                            £
                                                            {formatCurrency(
                                                                Number(
                                                                    deposit2?.amount,
                                                                ),
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="viewTotalPrice">
                                                        <div className="contentTotalDeposit">
                                                            Deposit due now
                                                        </div>
                                                        <div className="priceDeposit">
                                                            £
                                                            {formatCurrency(
                                                                roundedTotalDeposit,
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="viewTotalPrice">
                                                        <div className="contentTotalDeposit">
                                                            Balance Due Date
                                                        </div>
                                                        <div className="priceDeposit">
                                                            {moment(
                                                                totalDueday,
                                                                'DD/MM/YYYY',
                                                            )
                                                                .utc()
                                                                .format(
                                                                    'DD/MM/YYYY',
                                                                )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        {roundedTotalDeposit !==
                                            roundedTotalPrice &&
                                            Number(deposit2?.amount) === 0 && (
                                                <>
                                                    <div className="viewTotalPrice">
                                                        <div className="contentTotalDeposit">
                                                            Deposit due now
                                                        </div>
                                                        <div className="priceDeposit">
                                                            £
                                                            {formatCurrency(
                                                                roundedTotalDeposit,
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="viewTotalPrice">
                                                        <div className="contentTotalDeposit">
                                                            Balance Due Date
                                                        </div>
                                                        <div className="priceDeposit">
                                                            {moment(
                                                                totalDueday,
                                                                'DD/MM/YYYY',
                                                            )
                                                                .utc()
                                                                .format(
                                                                    'DD/MM/YYYY',
                                                                )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                    </div>
                                )}
                                {(checkEvent === true || type === 'Event') && (
                                    <div>
                                        {roundedTotalDeposit !==
                                            roundedTotalPrice && (
                                            <div className="viewTotalPrice">
                                                <div className="contentTotalDeposit">
                                                    Deposit due now
                                                </div>
                                                <div className="priceDeposit">
                                                    £
                                                    {formatCurrency(
                                                        roundedTotalDeposit,
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {Number(roundedTotalDeposit) !==
                                            Number(roundedTotalPrice) && (
                                            <div className="viewTotalPrice">
                                                <div className="contentTotalDeposit">
                                                    Balance Due Date
                                                </div>
                                                <div className="priceDeposit">
                                                    {moment(
                                                        totalDueday,
                                                        'DD/MM/YYYY',
                                                    )
                                                        .utc()
                                                        .format('DD/MM/YYYY')}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Spin>
                {priceAgentComment && (
                    <PricingBookingAndAdditionalAgentComment
                        showEdit={showEditAgentComment}
                        dataAgentComment={priceAgentComment}
                        changeShowEdit={() =>
                            showComponentEditAgentComment(!showEditAgentComment)
                        }
                        quoteTuorName={quoteTitle}
                        refetch={refetchValue}
                        roleBtnEditOnHeader={roleBtn}
                        avatarAgent={avatarPricing}
                        loading={isLoading}
                        setLoading={setIsloading}
                    />
                )}
                {!priceAgentComment && showEditAgentComment && (
                    <PricingBookingAndAdditionalAgentComment
                        showEdit={showEditAgentComment}
                        dataAgentComment={null}
                        changeShowEdit={() =>
                            showComponentEditAgentComment(!showEditAgentComment)
                        }
                        quoteTuorName={quoteTitle}
                        refetch={refetchValue}
                        roleBtnEditOnHeader={roleBtn}
                        avatarAgent={avatarPricing}
                        loading={isLoading}
                        setLoading={setIsloading}
                    />
                )}
                <div className="areaChoosen">
                    {dataVersionContenfull || dataReadyToBook ? (
                        <div>
                            <ReadyToBookComponent
                                dataReadyToBookComponent={
                                    dataReadyToBook || dataVersionContenfull
                                }
                                toTalDeposit={roundedTotalDeposit}
                                toTalPrice={roundedTotalPrice}
                                balenceDueDay={totalDueday}
                                deposit2={deposit2}
                                deposit3={deposit3}
                                checkEvent={checkEvent}
                            />
                        </div>
                    ) : (
                        <Skeleton active={true} />
                    )}
                </div>
                <div className="areaLogo">
                    <img
                        alt=""
                        className="imageGoftLogo"
                        src={images.awardGoflLogo}
                    />
                </div>
            </PricingAndBookingStyle>
        </>
    );
};

export default React.memo(PricingAndBookingComponent);
