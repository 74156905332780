import React, { useEffect, useState } from 'react';
import WhatOutGolferSayComponentStyle from './style';
import { getImageApi } from 'src/api/apiGetImage';
import { Skeleton } from 'antd';
import useQueryApi from 'src/hooks/useApiQuery';

interface WhatOutGolferSayProps {
    imageId?: string | undefined;
    title?: string;
    content?: string;
}

const WhatOutGolferSayComponent: React.FC<WhatOutGolferSayProps> = ({
    imageId,
    title,
    content,
}) => {
    const [dataImage, setDataImage] = useState<any>();

    const { data, isLoading, isError, error } = useQueryApi(
        ['image', imageId],
        () => getImageApi(imageId),
        {
            enabled: !!imageId,
            onSuccess: (data: any) => {
                console.log('Data fetched successfully:', data);
            },
            onError: (err: any) => {
                console.error('Error fetching data:', err);
            },
        },
    );

    useEffect(() => {
        if (data) {
            setDataImage(data);
        }
    }, [data]);

    return (
        <WhatOutGolferSayComponentStyle className="groupWhyItem">
            {data ? (
                <div className="allItem">
                    {dataImage?.fields?.file?.url && (
                        <img
                            alt=""
                            className="iconWhy"
                            src={`https:${dataImage?.fields?.file?.url}`}
                        />
                    )}

                    <div className="groupDiscription">
                        <p className="titleItem">{title}</p>
                        <p className="discriptionItem">{content}</p>
                    </div>
                </div>
            ) : (
                <Skeleton.Image active={true} className="allItem" />
            )}
        </WhatOutGolferSayComponentStyle>
    );
};

export default WhatOutGolferSayComponent;
