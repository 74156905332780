import React, { useState } from 'react';
import { StyledModalSendMess } from './style';
import TextArea from 'antd/es/input/TextArea';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { newQuoteTitleSelector } from 'src/redux/selectors/editQuoteTitleSelecter';
import { toast } from 'react-toastify';
import { useMutationApi } from 'src/hooks/useApiMutation';
import { addNoteIntoCRM } from 'src/api/apiAddNoteIntoCRM';
import { useLocation } from 'react-router-dom';

interface ModalSendMessageProps {
    checkEvent?: any;
    close?: () => void;
}

export const ModalSendMessenge: React.FC<ModalSendMessageProps> = ({
    checkEvent,
    close,
}) => {
    const [valueMessenger, setValueMessenger] = useState<any>('');
    const [validate, setValidate] = useState<boolean>(true);
    const newQuoteTitle = useSelector(newQuoteTitleSelector);
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const { mutate } = useMutationApi<{ id: any | string; param: any }, any>(
        ({ id, param }) => addNoteIntoCRM(id, param),
        {
            onSuccess: (response) => {
                toast.success(`Email sent successfully!`);
                close?.();
                setValueMessenger('');
            },
            onError: (error) => {
                toast.error(
                    'Unable to deliver your message at the moment, please try again shortly',
                );
                close?.();
                setValueMessenger('');
            },
        },
    );

    const changeMessend = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setValueMessenger(value);
    };

    const sendMessenger = () => {
        const dataParam = {
            title: newQuoteTitle,
            content: valueMessenger,
        };
        if (valueMessenger) {
            setValidate(true);
            mutate({ id: id, param: dataParam });
        } else setValidate(false);
    };
    return (
        <StyledModalSendMess>
            <TextArea
                value={valueMessenger}
                onChange={changeMessend}
                placeholder="Type your message here ..."
                autoSize={{ minRows: 10, maxRows: 20 }}
                status={!validate ? 'error' : ''}
            />

            <Button
                type="primary"
                className="btnSendMessage"
                onClick={sendMessenger}
            >
                Send Message
            </Button>
        </StyledModalSendMess>
    );
};
